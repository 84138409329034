
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import Image from "gatsby-image/withIEPolyfill"
import { FluidImage } from "@components/Image"
// import BackgroundImage from 'gatsby-background-image'
import { useTranslation } from 'react-i18next'

// import Link from "@components/Link"
import { useURL } from "@hooks/useURL"
import { useAudio } from "@hooks/useAudio"
import PlaySVG from '@images/Common__btn__audio-play.svg'
import StopSVG from '@images/Common__btn__audio-stop.svg'

import css from "@css/components/blocks/download/VoiceData.module.styl"

export default function DownloadVoiceData()
{
    // const [playing, currentTime, play, pause, jump] = useAudio("/sounds/demo.mp3");
    const {t} = useTranslation()

    const { miraiImg } = useStaticQuery(graphql`
        query {
            miraiImg : file(relativePath: { eq: "DownloadVoice__img__comachi-2.png" }) {
                childImageSharp {
                    fluid(maxWidth: 263) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    const urls = useURL()
    const [playId, setPlayId] = React.useState(-1)
    const [isPlay, setIsPlay] = React.useState(false)
    const currentAudioId = React.useRef(null)

    const callback = () => {
        setPlayId(-1)
        setIsPlay(false)
    }
    const audioData = [
        useAudio("/sounds/VoiceSample.mp3", callback),
    ]
    
    const data = [
        {
            title: t('download__voiceDataSectionTitle1'),
            text: t('download__voiceDataSectionText1'),
            text2: t('download__voiceDataSectionText2'),
            voices: [{
                    label: t('download__voiceDataSectionSoundLabel1'),
                    id: 0,
                }
            ],
            section: [{
                links: [
                    {
                        label: t('download__voiceDataSectionButton1-1'),
                        url: urls.voiceZip,
                    }, {
                        label: t('download__voiceDataSectionButton1-2'),
                        url: urls.voiceRepo
                    }
                ],
            }]
        }
    ]

    const onPlayAudio = (id) => {
        let audio = audioData[id]

        if( currentAudioId.current === id ){
            if (audio.getPaused()) {
                audio.play()
                setIsPlay(true)
                setPlayId(id)
            } else {
                audio.pause()
                audio.setCurrentTime(0)
                setIsPlay(false)
                setPlayId(-1)
            }
        } else {
            if (currentAudioId.current !== null){
                audioData[currentAudioId.current].pause()
                audioData[currentAudioId.current].setCurrentTime(0)
            }
            audio.play()
            setIsPlay(true)
            setPlayId(id)
        }
        currentAudioId.current = id
    }
    // React.useEffect(()=>{
    //     if (playAudioId === null) { return }
    //     let audio = audioData[playAudioId]
    //     if ( audio.getPaused() ){
    //         audio.play()
    //     } else {
    //         audio.pause()
    //         audio.setCurrentTime(0)
    //     }
    // }, [isPlay])

    return(
        <div className={css.container}>
            <div className={css.content}>
                <figure className={css.thumbnail}>
                    {/* <Image
                        fluid={miraiImg.childImageSharp.fluid}
                        objectFit="contain"
                        objectPosition="50% 50%"
                    /> */}
                    <FluidImage data={miraiImg}/>
                </figure>
                <div className={css.detail}>
                    {
                        data.map(({ title, text, text2, section, voices },i )=>{
                            return(
                                <section key={i} className={css.section}>
                                    <h4 className={css.title}>{title}</h4>
                                    <div className={css.text} dangerouslySetInnerHTML={{ __html: text}}/>
                                    <div className={css.voices}>
                                        <ul>
                                        { voices.map( ({label, id},j )=>{
                                            return (
                                                <li key={j}>
                                                    <span className={css.button} onClick={()=> onPlayAudio(id)}>
                                                        { id === playId ?
                                                            <StopSVG/>
                                                            :
                                                            <PlaySVG />
                                                        }
                                                    </span>
                                                    <span className={css.label}>{label}</span>
                                                </li>
                                            )
                                        })}
                                        </ul>
                                    </div>
                                    <div className={css.text2} dangerouslySetInnerHTML={{ __html: text2 }} />
                                    <div className={css.content}>
                                        {
                                            section.map(( { label, links },j)=>{
                                                return(
                                                    <div key={j} className={css.section}>
                                                        { label && <p className={css.label}>{label}</p> }
                                                        <p className={css.links}>
                                                            {
                                                                links.map(({ label, url }, k) => {
                                                                    return (
                                                                        <a href={url} className={css.button} key={i + j + k} target="_blank" rel="noopener noreferrer">
                                                                            {label}
                                                                        </a>
                                                                    )
                                                                })
                                                            }
                                                        </p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </section>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}