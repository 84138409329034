
import React from "react"

import { useTranslation } from 'react-i18next'
import Mailto from '@components/blocks/global/ContactMailto'

import css from "@css/components/blocks/download/Gideline.module.styl"

export default function DownloadGideline()
{
    const {t} = useTranslation()
    let texts = t('download__gideline', { returnObjects: true })
    let html = ""
    for(let text of texts){
        html += text
    }

    let contactText = t('download__gidelineContact', { returnObjects: true })
    // "問い合わせ先（<a href="miraikomachi&#64;bandainamcostudios.co.jp">miraikomachi&#64;bandainamcostudios.co.jp</a>）<br>",

    const makeText = () => {
        let doms = []
        contactText.map((text,i)=>{
            if( i === 2){
                doms.push(<span key={`${i}_mail`}><Mailto/></span>)
            }
            doms.push(<span key={i} dangerouslySetInnerHTML={{__html: text}}/>)
        })
        return doms
    }
    return(
        <div className={css.container}>
            <div dangerouslySetInnerHTML={{__html: html}}/>
            <section>
                <p>
                    {makeText()}
                </p>
            </section>
        </div>
    )
}
