import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import Image from "gatsby-image/withIEPolyfill"
import { FluidImage } from "@components/Image"
import { useTranslation } from 'react-i18next'
// import BackgroundImage from 'gatsby-background-image'
// import { Controller, Scene } from 'react-scrollmagic';

// import MiraiMessage from '@components/MiraiMessage'

import css from "@css/components/blocks/download/TermsOfUse.module.styl"


export default function DownloadTermsOfUse()
{
    const q = useStaticQuery(graphql`
        query {
            t01 : file(relativePath: { eq: "Download__img__talk-01@2x.png" }) {
                childImageSharp {
                    fluid(maxWidth: 364) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            t02 : file(relativePath: { eq: "Download__img__talk-02@2x.png" }) {
                childImageSharp {
                    fluid(maxWidth: 364) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            t03 : file(relativePath: { eq: "Download__img__talk-03@2x.png" }) {
                childImageSharp {
                    fluid(maxWidth: 364) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    const miraiRef = React.useRef()
    const [currentBalloon, setCurrentBalloon] = React.useState(0)
    const {t} = useTranslation()
    let data = [
        {
            title: t('download__termsSectionTitle1'),
            text: t('download__termsSectionText1'),
            ref: React.useRef(),
            img: q.t01,
        },{
            title: t('download__termsSectionTitle2'),
            text: t('download__termsSectionText2'),
            ref: React.useRef(),
            img: q.t02,
        },{
            title: t('download__termsSectionTitle3'),
            text: t('download__termsSectionText3'),
            ref: React.useRef(),
            img: q.t03,
        }
    ]

    const onSectionAction = (id, event) => {
        // console.log(id,event.state)
        if( event.state === "DURING"){
            setCurrentBalloon(id)
        }
        if( id === 0 && event.state === "BEFORE"){
            setCurrentBalloon(-1)
        }
    }

    React.useEffect(()=>{

    },[])


    return(
        <React.Fragment>
            <div className={css.container}>
                { data.map(( {title, text, ref, img}, i)=>{
                    return(
                        <section className={css.section} key={i}>
                            <div id={`${css.trigger}_${i}`}/>
                            <h4 className={css.title}>{title}</h4>
                            <div className={css.detail}>
                                <p className={css.text} dangerouslySetInnerHTML={{__html: text}}/>
                                <figure className={css.image}>
                                    <FluidImage data={img}/>
                                </figure>
                            </div>
                        </section>
                    )
                })}
            </div>

                {/*<Scene duration={200} triggerElement={`#trigger_2`} indicators={true}>
                    { (progress, event) => {
                        // setBalloon2(true)
                        return <div/>
                    }}
                </Scene>
                <Scene duration={200} triggerElement={`#trigger_3`} indicators={true}>
                    { (progress, event) => {
                        // setBalloon3(true)
                        return <div/>
                    } }
                </Scene>*/}
        </React.Fragment>
    )
}
