import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FluidImage } from "@components/Image"
// import Image from "gatsby-image"
// import BackgroundImage from 'gatsby-background-image'
import { useTranslation } from 'react-i18next'

import css from "@css/components/blocks/download/OfficialSetting.module.styl"

export default function DownloadTermsOfUse()
{
    const q = useStaticQuery(graphql`
        query {
            img01_1 : file(relativePath: { eq: "DownloadVoice__img__chara-r-01@2x.png" }) {
                childImageSharp {fluid(maxWidth: 300) { ...GatsbyImageSharpFluid } }
            }
            img01_2 : file(relativePath: { eq: "DownloadVoice__img__chara-r-02@2x.png" }) {
                childImageSharp {fluid(maxWidth: 300) { ...GatsbyImageSharpFluid } }
            }
            img01_3 : file(relativePath: { eq: "DownloadVoice__img__chara-r-03@2x.png" }) {
                childImageSharp {fluid(maxWidth: 300) { ...GatsbyImageSharpFluid } }
            }
            img02_1 : file(relativePath: { eq: "DownloadVoice__img__chara-b-01@2x.png" }) {
                childImageSharp {fluid(maxWidth: 300) { ...GatsbyImageSharpFluid } }
            }
            img02_2 : file(relativePath: { eq: "DownloadVoice__img__chara-b-02@2x.png" }) {
                childImageSharp {fluid(maxWidth: 300) { ...GatsbyImageSharpFluid } }
            }
            img02_3 : file(relativePath: { eq: "DownloadVoice__img__chara-b-03@2x.png" }) {
                childImageSharp {fluid(maxWidth: 300) { ...GatsbyImageSharpFluid } }
            }
        }
    `)
    const {t} = useTranslation()
    let data = [
        {
            label: t('download__officialSettingSection1_Title2'),
            value: t('download__officialSettingSection1_Text2')
        },{
            label: t('download__officialSettingSection1_Title3'),
            value: t('download__officialSettingSection1_Text3')
        },{
            label: t('download__officialSettingSection1_Title4'),
            value: t('download__officialSettingSection1_Text4')
        },{
            label: t('download__officialSettingSection1_Title5'),
            value: t('download__officialSettingSection1_Text5')
        }
    ]
    let data2 = [
        {
            label: t('download__officialSettingSection2_Title2'),
            value: t('download__officialSettingSection2_Text2')
        },{
            label: t('download__officialSettingSection2_Title3'),
            value: t('download__officialSettingSection2_Text3')
        },{
            label: t('download__officialSettingSection2_Title4'),
            value: t('download__officialSettingSection2_Text4')
        },{
            label: t('download__officialSettingSection2_Title5'),
            value: t('download__officialSettingSection2_Text5')
        }
    ]
    return(
        <div className={css.container}>
            <section className={css.block}>
                <div>
                    <div className={css.paragraph}>
                        <h3>{t('download__officialSettingSection1_Name')}</h3>
                        <p dangerouslySetInnerHTML={{__html: t('download__officialSettingSection1_Desc')}}>
                        </p>
                    </div>
                    { data.map(({label, value},i)=>{
                        return(
                            <div className={css.section} key={i}>
                                <h4 className={css.label}>{label}</h4>
                                <p className={css.value} dangerouslySetInnerHTML={{__html: value}}/>
                            </div>
                        )
                    })}
                </div>
                <div className={css.images}>
                    <div>
                        <FluidImage data={q.img01_1} />
                    </div>
                    <div>
                        <div>
                            <FluidImage data={q.img01_2} />
                        </div>
                        <div>
                            <FluidImage data={q.img01_3} />
                        </div>
                    </div>
                </div>
            </section>
            <section className={css.block}>
                <div>
                    <div className={css.paragraph}>
                        <h3>{t('download__officialSettingSection2_Name')}</h3>
                        <p dangerouslySetInnerHTML={{__html: t('download__officialSettingSection2_Desc')}}>
                        </p>
                    </div>
                    { data2.map(({label, value},i)=>{
                        return(
                            <div className={css.section} key={i}>
                                <h4 className={css.label}>{label}</h4>
                                <p className={css.value} dangerouslySetInnerHTML={{__html: value}}/>
                            </div>
                        )
                    })}
                </div>
                <div className={css.images_r}>
                    <div>
                        <FluidImage data={q.img02_1} />
                    </div>
                    <div>
                        <div>
                            <FluidImage data={q.img02_2} />
                        </div>
                        <div>
                            <FluidImage data={q.img02_3} />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
