
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import Image from "gatsby-image/withIEPolyfill"
import { FluidImage } from "@components/Image"
// import BackgroundImage from 'gatsby-background-image'
import { useTranslation } from 'react-i18next'

// import Link from "@components/Link"
import { useURL } from "@hooks/useURL"

import css from "@css/components/blocks/download/ModelData.module.styl"

export default function DownloadModelData()
{
    const {t} = useTranslation()

    const { miraiImg, miraiImgSp } = useStaticQuery(graphql`
        query DownloadModelDataQuery {
            miraiImg : file(relativePath: { eq: "DownloadModel__img__model@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1280) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            miraiImgSp : file(relativePath: { eq: "DownloadModel__img__model-sp@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1280) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    const urls = useURL()
    const data = [
        {
            title: t('download__modelDataSectionTitle1'),
            text: t('download__modelDataSectionText1'),
            section: [{
                label: 'Unity 2018.1.f4 or later',
                links: [
                    {
                        label: t('download__modelDataSectionButton1-1'),
                        url: urls.modelUnityZip,
                    }, {
                        label: t('download__modelDataSectionButton1-2'),
                        url: urls.modelUnityRepo
                    }
                ],
            }, {
                label: 'Unity 2019.4 or later',
                links: [
                    {
                        label: t('download__modelDataSectionButton1-1'),
                        url: urls.modelUnityZip201904,
                    }, {
                        label: t('download__modelDataSectionButton1-2'),
                        url: urls.modelUnityRepo201904
                    }
                ],
            }]
        },{
            title: t('download__modelDataSectionTitle4'),
            text: t('download__modelDataSectionText4'),
            section: [{
                label: 'Blender 2.83.13 or later',
                links: [
                    {
                        label: t('download__modelDataSectionButton4-1'),
                        url: urls.modelBlenderZip,
                    }, {
                        label: t('download__modelDataSectionButton4-2'),
                        url: urls.modelBlenderRepo
                    }
                ],
            }]
        },{
            title: t('download__modelDataSectionTitle2'),
            text: t('download__modelDataSectionText2'),
            section:[{
                links: [
                    {
                        label: t('download__modelDataSectionButton2-1'),
                        url: urls.modelVrmZip
                    },{
                        label: t('download__modelDataSectionButton2-2'),
                        url: urls.modelVrmRepo
                    }
                ]
            }]
        },{
            title: t('download__modelDataSectionTitle3'),
            text: t('download__modelDataSectionText3'),
            section: [{
                links: [
                    {
                        label: t('download__modelDataSectionButton3-1'),
                        url: urls.modelMmdZip
                    },{
                        label: t('download__modelDataSectionButton3-2'),
                        url: urls.modelMmdRepo
                    }
                ]
            }]
        }
    ]

    return(
        <div className={css.container}>
            <div className={css.content}>
                <figure className={css.thumbnail}>
                    <FluidImage data={miraiImg}/>
                </figure>
                <figure className={css.thumbnailSp}>
                    <FluidImage data={miraiImgSp} />
                </figure>
                <div className={css.detail}>
                    {
                        data.map(( { title, text, section },i )=>{
                            return(
                                <section key={i} className={css.section}>
                                    <h4 className={css.title}>{title}</h4>
                                    <p className={css.text}>{text}</p>
                                    <div className={css.content}>
                                        {
                                            section.map(( { label, links },j)=>{
                                                return(
                                                    <div key={j} className={css.section}>
                                                        { label && <p className={css.label}>{label}</p> }
                                                        <p className={css.links}>
                                                            {
                                                                links.map(({ label, url }, k) => {
                                                                    return (
                                                                        <a href={url} className={css.button} key={i + j + k} target="_blank" rel="noopener noreferrer">
                                                                            {label}
                                                                        </a>
                                                                    )
                                                                })
                                                            }
                                                        </p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </section>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}