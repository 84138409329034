import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from 'react-i18next'
// import { Controller, Scene } from 'react-scrollmagic';
import { useEventListener } from '@hooks/useEventListener'

// import MiraiMessage from '@components/MiraiMessage'
import SEO from "@components/Seo"

import FooterSns from "@components/blocks/global/FooterSNS"
import Footer from "@components/blocks/global/Footer"
// import Header from "@components/blocks/global/Header"
import PageHeader from "@components/blocks/common/PageHeader"
import PageSectionTitle from "@components/blocks/common/PageSectionTitle"
import TermsOfUse from "@components/blocks/download/TermsOfUse"
import ModelData from "@components/blocks/download/ModelData"
import DownloadList from "@components/ContentList-v2"
import OfficialSetting from "@components/blocks/download/OfficialSetting"
import Gideline from "@components/blocks/download/Gideline"
import VoiceData from "@components/blocks/download/VoiceData"

import css from "@css/components/blocks/common/Page.module.styl"

export default function Download({data})
{
    const [mounted, setMounted] = React.useState(false)
    const [resized, setResized] = React.useState(0)
    const {t} = useTranslation()
	const { allWordpressWpDownload } = data
    const scrollId = React.useRef(-1)

    const onResize = (e) => {
        setResized(resized + 1)
    }
    useEventListener('resize', onResize)

	// const onTrigger = (progress, event, id) => {
 //        const { state, type } = event
 //        console.log(state, type, id)

 //        //DURING AFTER
 //        if( state === "DURING" && type ==="start"){
 //            // console.log('id', id)
 //            scrollId.current = id
 //        }
	// }

	const downloadData = allWordpressWpDownload.edges
    const containerRef = React.useRef()

    const infoRefs = React.useRef([])

    React.useEffect(()=>{
        setMounted(true)
    },[resized, mounted])
	return (
		<React.Fragment>
			<SEO title={'DOWNLOAD'} />
			<main className={css.container} id="main" ref={containerRef}>
				<PageHeader l1="DOWNLOAD" l2="ダウンロード"/>
				<div className={css.content}>
					<div className={css.download}>
                        <section className={css.borderSection}>
                            <PageSectionTitle l1={t("download__sectionTitle1-1")} l2={t("download__sectionTitle1-2")}/>
                            <TermsOfUse/>
                        </section>
                        <section className={css.borderSection}>
                            <PageSectionTitle l1={t("download__sectionTitle2-1")} l2={t("download__sectionTitle2-2")}/>
                            <ModelData/>
                        </section>
                        <section className={css.borderSection} id="voices">
                            <PageSectionTitle l1={t("download__sectionTitle6-1")} l2={t("download__sectionTitle6-2")} />
                            <VoiceData />
                        </section>
                        <section className={css.borderSection}>
                            <PageSectionTitle l1={t("download__sectionTitle3-1")} l2={t("download__sectionTitle3-2")}/>
                            <DownloadList type={'download'} data={downloadData} isThumbnailTitle={false} isDownload={true}/>
                        </section>
                        <section className={css.borderSection}>
                            <PageSectionTitle l1={t("download__sectionTitle4-1")} l2={t("download__sectionTitle4-2")}/>
                            <OfficialSetting/>
                        </section>
                        <section className={css.section} id="gideline">
                            <PageSectionTitle l1={t("download__sectionTitle5-1")} l2={t("download__sectionTitle5-2")}/>
                            <Gideline/>
                        </section>
					</div>
				</div>
			</main>
			<FooterSns/>
			<Footer/>
		</React.Fragment>
	)
}

// export default function Download({data})
// {
//     const [mounted, setMounted] = React.useState(false)
//     const [resized, setResized] = React.useState(0)
//     const {t} = useTranslation()
//     const { allWordpressWpDownload } = data
//     const scrollId = React.useRef(-1)

//     const onResize = (e) => {
//         setResized(resized + 1)
//     }
//     useEventListener('resize', onResize)

//     // const onTrigger = (progress, event, id) => {
//  //        const { state, type } = event
//  //        console.log(state, type, id)

//  //        //DURING AFTER
//  //        if( state === "DURING" && type ==="start"){
//  //            // console.log('id', id)
//  //            scrollId.current = id
//  //        }
//     // }

//     const downloadData = allWordpressWpDownload.edges
//     const containerRef = React.useRef()
//     const sectionRefs = React.useRef([
//         React.createRef(),
//         React.createRef(),
//         React.createRef(),
//         React.createRef(),
//         React.createRef()
//     ])

//     const infoRefs = React.useRef([])

//     React.useEffect(()=>{
//         setMounted(true)
//         let i = 0
//         for( let sct of sectionRefs.current ){
//             // console.log(sct.current.getBoundingClientRect())
//             const sctRect = sct.current
//             // const { top, height } = sct.current.getBoundingClientRect()
//             const heightRate = sctRect.clientHeight / containerRef.current.clientHeight
//             const topRate = sct.current.offsetTop / containerRef.current.clientHeight
//             infoRefs.current[i] = {heightRate: heightRate, topRate: topRate}
//             i++
//         }
//     },[resized, mounted])
//     return (
//         <React.Fragment>
//             <SEO title={'DOWNLOAD'} />
//             <div className={css.comachiMessage}>
//                 <MiraiMessage
//                     currentBalloon={scrollId.current}
//                     info={infoRefs}
//                     containerHeight={containerRef.current ? containerRef.current.clientHeight : 0}
//                 />
//             </div>
//             <main className={css.container} id="main" ref={containerRef}>
//                 <PageHeader l1="DOWNLOAD" l2="ダウンロード"/>
//                 <div className={css.content}>
//                     <div className={css.download}>
//                         <section ref={sectionRefs.current[0]} className={css.borderSection}>
//                             <PageSectionTitle l1="TERMS OF USE" l2={t("download__sectionTitle1-2")}/>
//                             <TermsOfUse/>
//                         </section>
//                         <section ref={sectionRefs.current[1]} className={css.borderSection}>
//                             <PageSectionTitle l1="3D MODEL DATA" l2={t("download__sectionTitle2-2")}/>
//                             <ModelData/>
//                         </section>
//                         <section ref={sectionRefs.current[2]} className={css.borderSection}>
//                             <PageSectionTitle l1="OFFICIAL MATERIAL" l2={t("download__sectionTitle3-2")}/>
//                             <DownloadList data={downloadData}/>
//                         </section>
//                         <section ref={sectionRefs.current[3]} className={css.borderSection}>
//                             <PageSectionTitle l1="OFFICIAL SETTINGS" l2={t("download__sectionTitle4-2")}/>
//                             <OfficialSetting/>
//                         </section>
//                         <section ref={sectionRefs.current[4]} className={css.section} id="gideline">
//                             <PageSectionTitle l1="CHARACTER USAGE GUIDELINES" l2={t("download__sectionTitle5-2")}/>
//                             <Gideline/>
//                         </section>
//                     </div>
//                 </div>
//             </main>
//             <FooterSns/>
//             <Footer/>
//         </React.Fragment>
//     )
// }

export const pageQuery = graphql`
    query {
        allWordpressWpDownload (sort: { fields: date, order: DESC }){
            edges {
            	node {
            		id
            		slug
            		title
            		content
            		date(formatString: "MMMM DD, YYYY")
            		featured_media {
                        localFile {
                            publicURL
                            childImageSharp {
                                fluid(maxWidth: 640) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
            		}
            		acf {
						download_link_title1
						download_link_url1
						download_link_title2
						download_link_url2
					}
            	}
            }
        }
    }
`