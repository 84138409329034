import React from 'react'

const useAudio = (url, callback) => {

    const [audio,setAudio] = React.useState();
    const [, _forceUpdate] = React.useState(false);
    const forceUpdate = () => _forceUpdate(prevState => !prevState);

    React.useEffect(() => {
        setAudio(new Audio(url))
    }, []);
    React.useEffect(()=>{
        if( !audio ) return
        audio.addEventListener("play", forceUpdate);
        audio.addEventListener("pause", forceUpdate);
        audio.addEventListener("timeupdate", forceUpdate);
        audio.addEventListener("ended", callback)

        return () => {
            audio.removeEventListener("play", forceUpdate);
            audio.removeEventListener("pause", forceUpdate);
            audio.removeEventListener("ended", forceUpdate);
            audio.removeEventListener("timeupdate", forceUpdate);
        };
    },[audio])

    const play = () => audio.play();
    const pause = () => audio.pause();
    const jump = value => (audio.currentTime += value);

    return {
        getPaused: () => audio && audio.paused,
        currentTime: audio && audio.currentTime,
        setCurrentTime: (time) => {
            if( audio ) {
                audio.currentTime = time
            }
        },
        play,
        pause,
        jump
    }
}
export { useAudio }
